/* .panel-title {
  position: relative;
}

.panel-title::after {
  content: "\f107";
  color: #333;
  top: -2px;
  right: 0px;
  position: absolute;
  font-family: "FontAwesome";
}

.panel-title[aria-expanded="true"]::after {
  content: "\f106";
} */

.card-header .panel-title:after {
  /* symbol for "opening" panels */
  font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
  content: "\e114"; /* adjust as needed, taken from bootstrap.css */
  float: right; /* adjust as needed */
  color: grey; /* adjust as needed */
}
.card-header .panel-title.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\e080"; /* adjust as needed, taken from bootstrap.css */
}
